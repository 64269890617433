import React, { useEffect, useState } from "react";
import apis from "../apis";
import FlipCountdown from "@rumess/react-flip-countdown";

const GenerateTimer = ({ schedularType }) => {
  const [ws, setWs] = useState(null);
  const [timer, setTimer] = useState(null);
  const [dateAndTime, setDateAndTime] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [limit, setLimit] = useState(0);
  const [allow, setAllow] = useState("");
  const [allowRolls, setAllowRolls] = useState([]);
  const [currSession, setCurrSession] = useState("");

  const getSessionListData = async () => {
    await apis.getSessionFilterList().then((data) => {
      setCurrSession(data[0]);
    });
  };

  useEffect(() => {
    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const hostname = window.location.hostname;
    const port = process.env.WEBSOCKET_PORT || 9000;
    const url = `${protocol}${hostname}:${port}/ws`;
    const socket = new WebSocket(url);
    setWs(socket);
    

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data === null) {
        setIsChecked(false);
      }
      if (
        data.type === `${schedularType}_TIMER_UPDATE` ||
        data.type === `STOP_${schedularType}_TIMER` ||
        data.type === `SET_${schedularType}_TIMER` ||
        data.type === `UPDATE_${schedularType}_TIMER`
      ) {
        if (data.type === `STOP_${schedularType}_TIMER`) {
          setIsChecked(false);
          setLimit(0);
          setDateAndTime("");
        }

        if (data.data) {
          setIsChecked(true);
          if (data.data !== null) {
            let newDate = data.data.endTime;

            // add 5hrs 30min to the date
            const date = new Date(newDate);
            date.setHours(date.getHours() + 5);
            date.setMinutes(date.getMinutes() + 30);
            const freshDate = date.toISOString().split(".")[0].slice(0, -3);

            setDateAndTime(freshDate);

            const endTimeWithSeconds = newDate.split(":");
            const endTime = `${endTimeWithSeconds[0]}:${endTimeWithSeconds[1]}`;

            setLimit(data.data.limit);
            if (!data.data.openFor.includes("All")) {
              setAllow("Specific");
              setAllowRolls(data.data.openFor.join(","));
            }
          }
        }
        setTimer(data.data);
      }
    };

    getSessionListData();

    // return () => {
    //   socket.close();
    // };
  }, []);

  const setTimerHandler = async () => {
    if(schedularType === "MESS"){
      if(limit === 0){
        alert("Please enter student count");
        return;
      }
      if(dateAndTime === ""){
        alert("Please enter end date & time");
        return;
      }
      if(allow === ""){
        alert("Please specify whom to allow");
        return;
      }
    } else if (schedularType === "HOSTEL") {
      if(dateAndTime === ""){
        alert("Please enter end date & time");
        return;
      }
      if(allow === ""){
        alert("Please specify whom to allow");
        return;
      }
    }

    const endTime = new Date(dateAndTime).toISOString();
    const studentLimit = parseInt(limit);
    let openForData = [];
    if (allow === "Specific") {
      openForData = allowRolls.split(",").map((roll) => roll.trim());
    } else {
      openForData = ["All"];
    }

    await apis
      .setSchedule({
        endTime,
        limit: studentLimit,
        type: schedularType,
        openFor: openForData,
        session: currSession,
      })
      .then((data) => {
        alert(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const updateTimerHandler = async () => {
    const newendTime = new Date(dateAndTime).toISOString();
    const studentLimit = parseInt(limit);
    let openForData = [];
    if (allow === "Specific") {
      openForData = allowRolls.split(",").map((roll) => roll.trim());
    } else {
      openForData = ["All"];
    }
    await apis
      .updateSchedule({
        endTime: newendTime,
        limit: studentLimit,
        type: schedularType,
        openFor: openForData,
        session: currSession,
      })
      .then((data) => {
        alert(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const stopTimerHandler = async () => {
    await apis
      .removeSchedule(schedularType)
      .then((data) => {
        alert(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const performOperation = (operation) => {
    if (operation === "stop") {
      setIsChecked(false);
      if (timer || (timer && timer.isActive)) {
        stopTimerHandler();
      }
      setLimit(0);
      setDateAndTime("");
    } else {
      setIsChecked(true);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="headPart" style={{ width: "100%" }}>
        <p style={{textAlign: 'center'}}>
          Open window for{" "}
          {schedularType === "MESS" ? "Mess Swap" : "Hostel Allotment"}{" "}
        </p>
        <div
          className={`switch ${isChecked ? "on" : "off"}`}
          // onClick={handleCheckboxChange}
        >
          <span
            className="checkState"
            onClick={() => performOperation(isChecked ? "stop" : "start")}
          >
            {isChecked ? "OPENED" : "CLOSED"}
          </span>
          <div className="slider"></div>
        </div>
      </div>
      {timer && (
        <div className="" style={{ width: "100%", margin: "10px auto" }}>
          <FlipCountdown
            hideYear
            hideMonth
            endAt={timer.endTime}
            onTimeUp={() => stopTimerHandler()}
            size={"small"}
            theme={"dark"}
          />
        </div>
      )}
      {isChecked && (
        <div className="formPart">
          {schedularType === "MESS" && (
            <div className="items7">
              <span>Students Count</span>
              <input
                type="number"
                name="student"
                id="student"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="studentCnt"
              />
            </div>
          )}
          <div className="items7">
            <span>End Date & time</span>
            <input
              type="datetime-local"
              name="start"
              id="start"
              value={dateAndTime}
              onChange={(e) => {
                setDateAndTime(e.target.value);
                console.log(e.target.value);
              }}
            />
          </div>
          <div className="items7">
            <span>Allow</span>
            <select value={allow} onChange={(e) => setAllow(e.target.value)}>
              <option value="">Select</option>
              <option value="All">All</option>
              <option value="Specific">Specific</option>
            </select>
          </div>
          {allow === "Specific" && (
            <textarea
              value={allowRolls}
              onChange={(e) => setAllowRolls(e.target.value)}
              placeholder="Enter Roll Numbers separated by comma"
              style={{ width: "100%", minHeight: "100px", maxHeight: "200px" }}
            />
          )}
        </div>
      )}
      {isChecked && (
        <div className="buttonPart">
          {timer || (timer && timer.isActive) ? (
            <button onClick={updateTimerHandler} className="primary">
              Update
            </button>
          ) : (
            <button onClick={setTimerHandler} className="primary">
              Save
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default GenerateTimer;
