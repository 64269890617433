import React from "react";
import { useForm } from "react-hook-form";
import apis from "../apis";
import ButtonLoader from "./ButtonLoader";

const AdminUpdatePopUp = ({ open, onClose, role, manageFaculty }) => {
  // handle form submission here
  const { register, handleSubmit } = useForm();
  const [btnLoading, setBtnLoading] = React.useState(false);

  const [batchList, setBatchList] = React.useState();

  React.useEffect(() => {
    const fetchBatchList = async () => {
      await apis.getBatchFilterListComplete().then((data) => {
        setBatchList(data);
      }).catch((err) => {
        console.log(err.message);
      })
    }
    fetchBatchList();
  }, []);

  const registerAdmin = async (data) => {
    await apis
      .addAdmin(data)
      .then((res) => {
        setBtnLoading(false);
        window.location.reload();
        alert("Admin Added");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  if (!open) return null;
  return (
    <>
      <div className="gateway-popup">
        <div className="gateway-popup-title">
          <h2>Add/Update Admin:</h2>
          <button onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="gateway-popup-content DocAddPopup-content MA multiselectPopUp">
          <form onSubmit={handleSubmit(registerAdmin)}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" required {...register("name")} />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" required {...register("email")} />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                required
                {...register("password")}
              />
            </div>
            {manageFaculty && <div className="form-group multiple-select">
              <label htmlFor="program">Select Program</label>
              <select name="adminType" id="adminType"{...register("program")} multiple>
                <option value="B.TECH.">B.Tech</option>
                <option value="M.TECH.">M.Tech</option>
                <option value="Ph.D.">PhD</option>
              </select>
              <label htmlFor="batch">Select Batch</label>
              <select name="batch" id="batch" {...register("batch")} multiple>
                <option disabled value="">
                  Select Batch
                </option>
                <option disabled value="">
                  B.Tech.
                </option>
                {batchList["B.TECH."]?.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch}
                  </option>
                ))}
                <option disabled value="">
                  M.Tech.
                </option>
                {batchList["M.TECH."]?.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch}
                  </option>
                ))}
                <option disabled value="">
                  Ph.D.
                </option>
                {batchList["Ph.D."]?.map((batch) => (
                  <option key={batch} value={batch}>
                    {batch}
                  </option>
                ))}
              </select>
              <label htmlFor="branch">Select Branch</label>
              <select
                name="branch"
                id="branch"
                {...register("branch")}
                multiple
              >
                <option value="CSE">CSE</option>
                <option value="ECE">ECE</option>
                <option value="MAE">MAE</option>
                <option value="MNC">MNC</option>
                <option disabled>M.Tech</option>
                <option value="AI & DS">AI & DS</option>
                <option value="SPML">SPML</option>
                <option value="VLSI & ES">VLSI & ES</option>
                <option value="Micro & Comm.">Micro & Comm.</option>
                <option value="EV">EV</option>
              </select>
            </div>}
            <div className="form-group multiple-select">
              <label htmlFor="adminType">Select Admin Type/Role</label>
              <select
                name="adminType"
                id="adminType"
                required
                {...register("role")}
                multiple
              >
                {!manageFaculty && (
                  <option value="Super Admin">Super Admin</option>
                )}
                {!manageFaculty && (
                  <option value="Academic Admin">Academic Admin</option>
                )}
                {!manageFaculty && <option value="DOSA">DOSA</option>}
                {!manageFaculty && (
                  <option value="Academic Office">Academic Office</option>
                )}
                {!manageFaculty && <option value="DOAA">DOAA</option>}
                {!manageFaculty && (
                  <option value="Finance Section">Finance Section</option>
                )}
                {!manageFaculty && (
                  <option value="Document Verifier">Document Verifier</option>
                )}
                {!manageFaculty && (
                  <option value="JOSAA-CSAB-CCMT Seat Allotment">JOSAA-CSAB-CCMT Seat Allotment</option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Electrical">Complaint-Electrical</option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Internet">Complaint-Internet</option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Civil">Complaint-Civil</option>
                )}
                {manageFaculty && (
                  <option value="Faculty">Faculty</option>
                )}
                {manageFaculty && (
                  <option value="HOD">HOD</option>
                )}
                {manageFaculty && (
                  <option value="Faculty Advisor">Faculty Advisor</option>
                )}
                {!manageFaculty && <option value="HAB">HAB</option>}
                {!manageFaculty && (
                  <option value="Warden">Hostel Warden</option>
                )}
              </select>
            </div>
            {!manageFaculty && <div className="form-group">
              <label htmlFor="adminType">Select Hostel (If Role is Warden)</label>
              <select
                name="adminType"
                id="adminType"
                required
                {...register("wardenHostel")}
              >
                {!manageFaculty && (
                  <option value="H1">H1</option>
                )}
                {!manageFaculty && (
                  <option value="H9">H9</option>
                )}
                {!manageFaculty && <option value="H7">H7</option>}
                {!manageFaculty && (
                  <option value="NBH">NBH</option>
                )}
                {!manageFaculty && <option value="GH">GH</option>}
                {!manageFaculty && <option value="KBH">KBH</option>}
                {!manageFaculty && <option value="KGH">KGH</option>}
              </select>
            </div>}
            {btnLoading ? (<button className="primary" type="submit">
              <ButtonLoader />
            </button>) : (<button className="primary" type="submit" onClick={() => setBtnLoading(true)}>
              Save and Update
            </button>)
            }
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminUpdatePopUp;
